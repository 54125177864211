import { request } from "@/request";

/**
 * @Author: Djy
 * @Date: 2024-17-20 17:44:13
 * @Description: 获取验证码（base64）
 */
export function getCaptcha() {
  return request({
    url: '/security/captcha/getCaptcha',
    method: 'get'
  })
}

/**
 * @Author: Djy
 * @Date: 2024-17-20 17:44:36
 * @Description: 登陆
 */
export function login(form) {
  return request({
    url: '/security/login',
    method: 'post',
    data: form
  })
}

/**
 * @Author: Djy
 * @Date: 2024-17-20 17:44:45
 * @Description: 退出登陆
 */
export function logout() {
  return request({
    url: '/security/logout',
    method: 'post',
  })
}
