import "core-js/modules/es.array.push.js";
import { whiteList } from '@/config/white-list';
import router from '@/router/index';
import { usePermissionStore } from '@/store/permission';
import { getAuthorization, removeAuthorization } from '@/utils/cookies';
// 初始化跳转次数(正式环境这步可以去掉)
let jumpCount = 0;
router.beforeEach(async (to, from, next) => {
  console.log('to:', to.path, ';form:', from.path, 'routerConfig beforeEach');
  if (jumpCount > 10) {
    // 删除 token，并重定向到登录页面
    removeAuthorization();
    console.log("路由跳转超过10次");
    ElMessage.error('路由错误，请联系管理员！！！');
    next({
      path: '/login'
    });
  }
  jumpCount++;
  try {
    if (getAuthorization()) {
      const permission = usePermissionStore();
      if (to.path === '/login') {
        if (Array.isArray(permission.routes) && permission.routes.length > 0) {
          next(permission.routes[0].path);
        } else {
          removeAuthorization();
          next();
          jumpCount = 0;
        }
      } else {
        let flag = [];
        judgeRouter(permission.routes, to.path, flag);
        if (!flag.includes(true)) {
          flag = [];
          await permission.setRoutes();
          judgeRouter(permission.routes, to.path, flag);
          if (flag.includes(true)) {
            next({
              ...to,
              replace: true
            });
          } else {
            next(permission.routes[0].redirect ? permission.routes[0].redirect : permission.routes[0].path);
          }
        } else {
          next();
          jumpCount = 0;
        }
      }
    } else {
      if (whiteList.includes(to.path)) {
        next();
        jumpCount = 0;
      } else {
        next('/login');
      }
    }
  } catch (e) {
    removeAuthorization();
    console.log("路由跳转发生异常", e);
    ElMessage.error('路由错误，请联系管理员！！！');
    next({
      path: '/login'
    });
  }
});
const judgeRouter = (routers, path, flag) => {
  if (Array.isArray(routers) && routers.length > 0) {
    routers.forEach(item => {
      if (item.path === path) {
        return flag.push(true);
      } else {
        judgeRouter(item.children, path, flag);
      }
    });
  }
};