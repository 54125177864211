import Cookies from 'js-cookie'

// key, value, time/天
export const setCookie = (key:any, value:any, time = 30) => {
  Cookies.set(key, value, { expires: time })
}

export const getCookie = (key:any) => {
  return Cookies.get(key)
}

export const removeCookie = (key:any) => {
  Cookies.remove(key)
}
export const setAuthorization = (value:any, time = 30) => {
  Cookies.set('Authorization', value, { expires: time })
}

export const getAuthorization = () => {
  return Cookies.get('Authorization')
}

export const removeAuthorization = () => {
  Cookies.remove('Authorization')
  localStorage.clear()
}