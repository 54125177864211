import {request} from '@/request'
/**
 * @Author: Djy
 * @Date: 2024-16-27 16:56:51
 * @Description: 删除用户
 */
export function deleteSysUserById(userId) {
  return request({
    url: '/sysUser/deleteSysUserById/' + userId,
    method: 'get'
  })
}

/**
 * @Author: Djy
 * @Date: 2024-18-20 18:11:40
 * @Description: 修改用户
 */
export function updateSysUser(form, headUrl) {
  return request({
    url: '/sysUser/updateSysUser',
    method: 'post',
    data: {
      "accountStatus": form.accountStatus,
      "birthday": form.birthday,
      "headUrl": headUrl,
      "phone": form.phone,
      "realName": form.realName,
      "remarks": form.remarks,
      "sex": form.sex,
      "userId": form.userId
    }
  })
}

/**
 * @Author: Djy
 * @Date: 2024-14-24 14:08:41
 * @Description: 查询用户详情
 */
export function getSysUser(userId) {
  return request({
    url: '/sysUser/getSysUser/' + userId,
    method: 'get'
  })
}

/**
 * @Author: Djy
 * @Date: 2024-19-19 19:54:53
 * @Description: 分页查询用户
 */
export function pageQueryUser(pageIndex, pageSize, form) {
  return request({
    url: '/sysUser/pageQueryUser/' + pageIndex + '/' + pageSize,
    method: 'post',
    data: form
  })
}

/**
 * @Author: Djy
 * @Date: 2024-18-20 18:11:40
 * @Description: 修改密码
 */
export function updatePassword(form) {
  return request({
    url: '/sysUser/updatePassword',
    method: 'post',
    data: form
  })
}
