import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/index.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/',
    name: 'home',
    component: Layout,
    redirect: '/config/menumanage',
    children: [
      {
        path: '/config/menumanage',
        component: () => import("@/views/config/menumanage/index.vue")
      }
    ]
  }
]

// 动态路由
export const trendsRoutes: Array<RouteRecordRaw> = [
  {
    // 'Error'要放在所有路由的最后
    path: '/**',
    name: 'errorpath',
    component: Layout,
    redirect: '/404',
    children: [
      {
        path: '/404',
        name: '/404',
        component: () => import('@/views/error/index.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
