import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { createPinia } from 'pinia'
const pinia = createPinia()
const app = createApp(App);

// 导入全局css样式
import '@/assets/styles/common/index.scss'

// 修改ElementPlus组件 css样式
import '@/assets/styles/update-plus-css/index.scss'

// 导入动态路由插件
import '@/router/router-config'

app.use(pinia)
  .use(router)
  .mount('#app')
