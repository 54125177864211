import { request } from '@/request'

/**
 * @Author: Djy
 * @Date: 2023-06-28 11:45:13
 * @Description: 获取菜单树（权限树）
 */
export function getMenuTree(systemIde:any, isOpen:number) {
  return request({
    url: '/sysMenu/getSysMenuTree/' + systemIde + '/' + isOpen,
    method: 'get'
  })
}

/**
 * @Author: Djy
 * @Date: 2023-11-24 15:31:00
 * @Description: 查询菜单详情
 */
export function getSysMenuById(id:number) {
  return request({
    url: '/sysMenu/getSysMenuById/' + id,
    method: 'get'
  })
}

/**
 * @Author: Djy
 * @Date: 2023-11-24 15:31:00
 * @Description: 添加菜单
 */
export function addMenu(form:any) {
  return request({
    url: '/sysMenu/addMenu',
    method: 'post',
    data: form
  })
}

/**
 * @Author: Djy
 * @Date: 2023-11-24 15:31:00
 * @Description: 修改菜单
 */
export function updateMenu(form:any) {
  return request({
    url: '/sysMenu/updateMenu',
    method: 'post',
    data: form
  })
}

/**
 * @Author: Djy
 * @Date: 2023-11-24 15:31:00
 * @Description: 删除菜单
 */
export function deleteMenuById(id: number) {
  return request({
    url: '/sysMenu/deleteMenuById/' + id,
    method: 'get'
  })
}
