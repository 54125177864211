// 用于网络请求的实例和请求方法
import { getAuthorization, removeAuthorization } from '@/utils/cookies'
import axios, { AxiosRequestConfig } from 'axios'
import { get } from 'lodash'
import {ElMessage} from "element-plus";

// 服务器ip和端口处理
export default function getbaseurl() {
  return process.env.VUE_APP_BASE_URL
}

// 创建请求实例
function createService() {
  const service = axios.create({
    baseURL: getbaseurl()
  })
  // 创建一个 axios 实例
  service.interceptors.request.use(
    // 请求拦截
    config => {
      return config
    },
    error => {
      // 发送失败
      ElMessage.error('协议发送失败！！！')
      // return Promise.reject(error)
    }
  )
  // 响应拦截（可根据具体业务作出相应的调整）
  service.interceptors.response.use(
    response => {
      const res = response.data as any
      // 浏览器状态判断
      if (response.status !== 200) {
        ElMessage({
          message: res.message || 'Error',
          type: 'error',
          duration: 2 * 1000
        })
        return Promise.reject(res.message || 'Error')
      } else {
        if (res.code !== 20000) {
          // 用户登录超时
          if (res.code == -10017) {
            ElMessage.error(res.message)
            removeAuthorization()
            // 用户登陆超时刷新
            location.reload()
          }
          ElMessage.error('服务器异常！！！')
          return Promise.reject(res.message || 'Error')
        } else {
          return res
        }
      }
    },
    error => {
      // 如果是取消请求类型则忽略异常处理
      let isDuplicatedType
      try {
        const errorType = (JSON.parse(error.message) || {}).type
        isDuplicatedType = errorType === 'DUPLICATED_REQUEST'
      } catch (error) {
        isDuplicatedType = false
      }
      if (isDuplicatedType) {
        ElMessage.error('接口重复请求！！！')
        return
      }

      const status = get(error, 'response.status')
      switch (status) {
        case 400:
          error.message = '请求错误'
          break
        default:
          error.message = '服务器异常'
          break
      }
      ElMessage.error('服务器响应异常')
      // return Promise.reject(error)
      return
    }
  )
  return service
}

export const service = createService()

// 创建请求方法
function createRequestFunction() {
  return function (config: AxiosRequestConfig) {
    const configDefault = {
      headers: {
        Authorization: getAuthorization() == null ? '' : getAuthorization(),
        'Content-Type': get(config, 'headers.Content-Type', 'application/json')
      },
      timeout: 15000,
      withCredentials: false,
      baseURL: getbaseurl(),
      data: {}
    }
    return service(Object.assign(configDefault, config))
  }
}
// 用于网络请求的实例和请求方法
export const request = createRequestFunction()
export const geturl = () => {
  return process.env.VUE_APP_BASE_URL
}