import {defineStore} from "pinia";

export const userStore = defineStore('user', {
  state: () => ({
    ossAddress: ''
  }),
  getters: {
    getOssAddress() {
      return this.ossAddress
    }
  },
  actions: {
    setOssAddress(address: string) {
      this.ossAddress = address
    }
  }
})